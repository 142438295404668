<template>
  <v-app>
    <v-main class="bg-blue-lighten-3">
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components'

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //

  }),
}
</script>
